import React, { useEffect, useState } from 'react';
import { Article } from '../models/Article';
import { getArticle, getArticles } from '../service/api';
import { useParams } from 'react-router-dom';
import OtherNews from '../components/OtherNews';
import { InfinitySpin } from 'react-loader-spinner';
import WpApiContent from '../components/parser/WpApiContent';
import ShowCat from '../components/categories/ShowCat';

const ArticlePage = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState<Article>({} as Article);
  const [articles, setArticles] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    fetchArticle();
    fetchArticles();
  }, [articleId]);

  useEffect(() => {
    if (article && article.title) setLoading(false);
  }, [article, articles]);

  const fetchArticles = async () => {
    const art = await getArticles(8);
    setArticles(art);
  };

  const fetchArticle = async () => {
    if (!articleId) return;
    const art = await getArticle(articleId);
    if (!art) return;
    setArticle(art);
  };

  return (
    <div className="max-w-screen-xl py-4 mx-auto space-y-16 p-4 xl:p-0 dark:bg-gray-900">
      {loading ? (
        <div className="justify-center flex h-screen items-center">
          <InfinitySpin width="200" color="#35245b" />
        </div>
      ) : article ? (
        <div className="lg:max-w-screen-xl mx-auto space-y-4 p-4 py-4 dark:bg-gray-900">
          <ShowCat categories={article.categories} />
          <div>
            <h1 className="text-5xl font-bold text-custom-400 mb-4 dark:text-white">
              {article?.title}
            </h1>
            <h2
              className="mt-2 text-custom-200 text-md tracking-tight text-justify whitespace-pre-line dark:text-gray-200 italic 
            font-semibold"
            >
              {article?.excerpt &&
                WpApiContent({ content: article?.excerpt ?? '' })}
            </h2>
            <div className="flex items-center space-x-4 mt-4 text-custom-200 text-md tracking-tight dark:text-gray-200">
              <div className="flex items-center space-x-2">
                <img
                  className="w-8 h-8 rounded-full"
                  src={article.author?.avatar_urls?.[24]}
                  alt="author"
                />
                <span>
                  {article.author?.name} -{' '}
                  {new Date(article?.date).toLocaleString()}
                </span>
              </div>
            </div>
            <div className="mt-8 lg:w-full">
              <img
                src={article?.cover ?? ''}
                alt="cover"
                className="w-full rounded-lg"
              />
              <div className="mt-8 text-lg tracking-tight text-justify whitespace-pre-line dark:text-gray-200">
                {article?.content &&
                  WpApiContent({ content: article?.content ?? '' })}
              </div>
            </div>
          </div>
          <div className="border-b-2 border-custom-400" />
          <OtherNews articles={articles.slice(0, 8)} />
        </div>
      ) : (
        <div className="justify-center flex">
          <h1 className="text-5xl font-bold text-custom-400 mb-4 dark:text-white">
            No Article Found
          </h1>
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
