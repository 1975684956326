import React from 'react';
import { Article } from '../models/Article';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import ShowCat from './categories/ShowCat';
interface Props {
  article: Article;
}
const PreviewBig = (props: Props) => {
  const { article } = props;
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundImage: `url("${article?.cover}")`,
      }}
      className={`rounded-lg bg-custom-200 shadow flex-col flex 
      bg-cover bg-center bg-no-repeat justify-between overflow-hidden
      dark:bg-gray-800 dark:border-gray-700 h-80 w-full 
      transition duration-100 ease-in-out transform 
      hover:ring-2 hover:ring-custom-300 border-2 border-custom-100
      hover:cursor-pointer  `}
      onClick={() => {
        navigate(`/articles/${article.id}`);
      }}
    >
      <ShowCat categories={article.categories} />
      <div
        className="p-4 space-between flex flex-col bg-opacity-90 rounded-b-md bg-custom-100 
      dark:bg-gray-800 dark:border-gray-700 justify-between h-2/5"
      >
        <div className="flex flex-col h-4/5 overflow-hidden">
          <span>
            <h5 className="text-white font-bold text-lg leading-6 tracking-tight mb-2 ">
              {article?.title}
            </h5>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PreviewBig;
