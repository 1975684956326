import React from 'react';
import { Article } from '../models/Article';
import PreviewBig from './PreviewBig';

const OtherNews = (props: any) => {
  const { articles } = props;
  return (
    <div>
      <h2 className="text-3xl font-bold text-custom-400 mb-4 dark:text-white">
        Más noticias
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 grid-rows-1">
        {articles.map((article: Article) => {
          return <PreviewBig key={article?.id} article={article} />;
        })}
      </div>
    </div>
  );
};

export default OtherNews;
