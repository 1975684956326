import React from 'react';
import { Article } from '../models/Article';
import PreviewBig from './PreviewBig';
import PreviewMedium from './PreviewMedium';

interface Props {
  articles: Article[];
}
const MainNews = (props: Props) => {
  const { articles } = props;
  return (
    <div>
      <h2 className="text-3xl font-bold text-custom-400 mb-4 dark:text-white">
        Actualidad
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {articles.map((article: Article) => {
          return <PreviewBig key={article?.id} article={article} />;
        })}
      </div>
    </div>
  );
};

export default MainNews;
