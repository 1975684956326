import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from './components/Navbar';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import ArticlePage from './pages/ArticlePage';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navbar />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <Home />,
        children: [
          {
            path: '/:category',
            element: <Home />,
          },
        ],
      },
      {
        path: 'articles/:articleId',
        element: <ArticlePage />,
      },
    ],
  },
]);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBzF9HkB1GM2jlkmaAdB9V1zuIrwtTJJWI',
  authDomain: 'fightclub365news.firebaseapp.com',
  projectId: 'fightclub365news',
  storageBucket: 'fightclub365news.appspot.com',
  messagingSenderId: '564135547419',
  appId: '1:564135547419:web:43205a24633e5fbd929ebe',
  measurementId: 'G-HQERY7CTYH',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
