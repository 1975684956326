import React from 'react';
import parse from 'html-react-parser';
import { InstagramEmbed, TwitterEmbed } from 'react-social-media-embed';
// import Gist from "super-react-gist";
interface Props {
  content: string;
}
const WpApiContent = (props: Props) => {
  const { content } = props;
  return parse(content, {
    replace: (domNode: any) => {
      if (domNode.data?.includes('\n')) {
        return <br />;
      } else if (domNode.data === ' .') {
        return <></>;
      } else if (
        domNode.name === 'iframe' &&
        domNode.parent?.parent?.attribs?.class !== 'getty embed image'
      ) {
        const style = domNode.attribs?.style?.split(';');
        if (style) {
          const width = style.find((s: any) => s.includes('width'));
          const height = style.find((s: any) => s.includes('height'));
          const maxWidth = style.find((s: any) => s.includes('max-width'));
          if (width && height) {
            const w = width.split(':')[1].split('!')[0];
            const h = height.split(':')[1].split('!')[0];
            const maxW = maxWidth.split(':')[1].split('!')[0];
            console.log(domNode.attribs.src, w, h, maxW);
            return (
              <>
                <iframe
                  src={domNode.attribs.src}
                  className="rounded-lg"
                  loading="lazy"
                  style={{
                    width: w,
                    height: h,
                    maxWidth: maxW,
                    overflowY: 'hidden',
                  }}
                />
              </>
            );
          }
        }
        return (
          <iframe
            src={domNode.attribs.src}
            loading="eager"
            className="rounded-lg"
          />
        );
      }
      //show instagram post
      else if (domNode.data?.includes('instagram')) {
        return (
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <InstagramEmbed url={domNode.data} width={328} />
          </div>
        );
      }
    },
  });
};

export default WpApiContent;
